<template>
  <section class="container mx-auto m-8">
    <h2 class="section-header"><slot>Testing</slot></h2>
  </section>
</template>

<script>
export default {
  name: "SectionHeader",
};
</script>
